<template>
  <BisnisModelPlnbbForm mode="Ubah" module="Bisnis Model PLNBB"> </BisnisModelPlnbbForm>
</template>

<script>
import BisnisModelPlnbbForm from './form';

const BisnisModelPlnbbUpdate = {
  name: 'BisnisModelPlnbbUpdate',
  components: { BisnisModelPlnbbForm },
};

export default BisnisModelPlnbbUpdate;
</script>
